@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.header-text {
  @apply text-xl font-bold leading-tight text-gray-50;
}

.main-text {
  @apply text-gray-50 font-medium text-base sm:text-base;
}

.main-text-dark-content {
  @apply text-gray-900 font-medium text-base sm:text-base;
}

.main-toast-text {
  @apply text-gray-900 font-bold text-base;
}

.detail-text {
  @apply text-sm text-gray-400;
}

.detail-text-dark-content {
  @apply text-sm text-gray-800;
}

.detail-link-text {
  @apply text-sm text-frblue;
}

.button-text {
  @apply text-base font-bold text-white;
}

.nav-item {
  @apply inline-flex items-center px-1 pt-1 border-b-2 text-lg font-medium border-solid;
}

.nav-item-inactive {
  @apply border-transparent text-gray-400 hover:border-gray-300 hover:text-gray-300;
}

.nav-item-active {
  @apply border-frblue text-gray-50;
}

.icon {
  @apply h-6 w-6 text-gray-50;
}

.icon-button-container {
  @apply active:bg-fryellow sm:hover:bg-frblue rounded-full p-1.5 bg-frorange h-9 w-9 self-center;
}

.list {
  @apply divide-y divide-gray-800;
}

.pushable {
  border: none;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  outline: none;
}

.pushable:focus {
  outline: none;
}
.front {
  display: block;
  transform: translateY(-6px);
}

.front-icon {
  display: flex;
  transform: translateY(-6px);
}

.pushable:active .front {
  transform: translateY(-2px);
}

.pushable:active .front-icon {
  transform: translateY(-2px);
}

.label-text {
  @apply text-gray-50 font-bold text-base;
}

.highlight-text {
  color: #ffbe0b !important;
}

.input {
  @apply input-text input-outline input-border input-background;
}

.input-text {
  @apply sm:text-sm py-2 pl-4 pr-1  text-black;
}

.input-outline {
  @apply outline-none  focus:outline-none;
}

.input-border {
  @apply border-4 border-gray-600 focus:border-fryellow;
}

.input-background {
  @apply block w-full bg-gray-300 focus:bg-gray-200;
}

.title {
  text-shadow: 2px 2px #fb5607;
}

.yellow-callout {
  text-shadow: 2px 2px #8338ec;
}

.purple-callout {
  text-shadow: 2px 2px #ffbe0b;
}

.custom-animated-handle {
  transition: transform 0.2s;
}

.slider-with-animated-handle:hover .custom-animated-handle {
  transform: scale(1.2);
}

.home-callout {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 200px;
}

.corner {
  @apply bg-frgreen h-2 w-2 rounded-full absolute;
}

@font-face {
  font-family: 'Cartridge-Bold';
  src: local('Cartridge-Bold'), url(./assets/Cartridge-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Cartridge-Regular';
  src: local('Cartridge-Regular'), url(./assets/Cartridge-Regular.woff) format('woff');
}

.cartridge-bold {
  font-family: 'Cartridge-Bold', Fallback, sans-serif;
}

.cartridge-reg {
  font-family: 'Cartridge-Regular', Fallback, sans-serif;
}

.apple-auth-btn-custom {
  @apply rounded-xl text-gray-50 bg-black flex gap-3 border-2 border-white px-6 font-medium;
}

.apple-auth-btn-custom > * {
  @apply self-center;
}
